.wallet__content {
  h1 {
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--gray-color);

    &-amount {
      display: flex;
      flex-direction: row;
      align-items: center;
      h4 {
        margin: 0 20px 0 0;
      }
      p {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
      }
    }
    &-add {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        margin: 0 5px 0 0;
      }
    }
  }
}
