.base__cart {
  margin-top: 11rem;
  padding-top: 3rem;

  @media screen and (max-width: 991.5px) {
    padding-top: 0;
  }

  @media screen and (max-width: 575.5px) {
    margin: 10rem 1rem 0;
  }
}
