.base__customer-account {
  margin-top: 8rem;
  padding: 3rem 0;





  @media screen and (max-width: 767.5px) {
    padding: 0 0 2rem;
  }

  @media screen and (max-width: 575.5px) {
    padding: 0 2rem 2rem;
  }

  @media screen and (max-width: 420px) {
    padding: 0 .5rem 2rem;
  }
}

