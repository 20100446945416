.addAddress-container {
  .inputCol {
    margin-bottom: 1rem;

    .btn-holder {
      text-align: center;
      margin-top: 1rem;
      a {
        text-decoration: none;
        text-align: center;

        button {
          text-decoration: none;
        }
      }
    }
  }
}
