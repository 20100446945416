.cart__list-item {
  height: 140px;
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  flex-direction: row;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--gray-color);
  .item__img {
    border: 2px solid #c5c2c2;
    border-radius: 40px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin-inline-end: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .item__rightSide {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    &-details {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;

      p {
        margin: 0;

        &.item-name {
          font-weight: 600;
        }

        &.item-brand {
          font-size: 12px;
          color: #929292;
        }
        &.item-price {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          p {
            &:first-of-type {
              margin-inline-end: 5px;
            }
          }
          span {
            &.original {
              color: var(--gray-color);
              text-decoration: line-through;
              margin-inline-end: 10px;
            }
            &.sale {
              font-size: 14px;
              color: var(--red-color);
            }
          }
        }
      }
      p.size {
        text-align: end;
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .item-counter {
        width: 150px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: #0000000f;
        border-radius: 20px;
        padding: 7px 10px;
        p {
          margin: 0;
        }
        i {
          cursor: pointer;
          padding: 3px;
          &:hover {
            background-color: var(--white-color);
            border-radius: 50%;
          }
        }
      }
      .item-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // width: 110px;
        svg {
          font-size: 1.3rem;
          color: var(--black-color);
          cursor: pointer;
        }
      }
    }

    @media screen and (max-width: 575.5px) {
      &-actions {
        .item-counter {
          width: 100px;
        }
      }
    }
  }

  @media screen and (max-width: 575.5px) {
    height: 180px;
    align-items: flex-start;

    .item__rightSide {
      &-details {
        flex-direction: column;
        margin-bottom: 0.5rem;
        justify-content: flex-start;

        .item-name {
          font-size: 13px;
          margin-bottom: 5px;
        }
        .item-price {
          margin-top: 0.5rem;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
      &-actions {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .item-counter {
          position: absolute;
          left: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .item__rightSide {
      &-details {
        .item-name {
          font-size: 12px;
        }
        .item-brand {
          font-size: 12px;
        }
        .item-price {
          margin-top: 0.5rem;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }
}
