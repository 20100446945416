:global {
  .p-dialog {
    border: 3px solid var(--gray-color) !important;
    border-radius: 0 !important;
    .p-dialog-header {
      border-radius: 0 !important;
      padding: 1.8rem 3rem 0 !important;

      .p-dialog-header-icons {
        position: absolute;
        top: 10px;
        right: 10px;

        &:focus {
          box-shadow: none;
        }
      }
    }
    .p-dialog-content {
      border-radius: 0 !important;
      padding: 0 3rem 2rem !important;
    }
  }
}

.form-box {
  padding: 1rem 0;

  .login-error {
    color: var(--red-color);
  }
}

.checkbox-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  :global {
    .p-checkbox {
      box-shadow: none;
      .p-checkbox-box.p-highlight {
        background: var(--black-color);
        border-color: var(--black-color);
      }
      &:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: var(--black-color);
      }
      &:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: var(--black-color);
        box-shadow: none;
      }
      &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: var(--black-color) !important;
        border-color: var(--black-color) !important;
      }
    }
  }
  label {
    margin-inline-start: 10px;
    cursor: pointer;
    font-size: 14px;

    a {
      text-decoration: underline !important ;
    }
  }
}

// button section

.buttonContainer {
  width: 100% !important;
  position: relative;
  display: flex;
  overflow: hidden;
  width: fit-content;
  border: 2px solid var(--black-color);
}

.dialog-btn {
  width: 50%;
  padding: 8px 20px;
  font-weight: 400;
  background-color: transparent;
  color: #000;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.active {
  //   background-color: #000;
  color: #fff;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #000;
  transition: transform 0.5s ease;
}

.termsLink {
  text-decoration: underline;
}
