.dropdownField {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  label {
    font-size: 14px;
    color: var(--gray-color);
    span {
      color: var(--red-color);
    }
  }
  :global {
    .p-dropdown {
      width: 100%;
      border-radius: 0;
      border: 0;
      border-bottom: 2px solid var(--gray-color);
      box-shadow: none !important;
      padding: 0.5rem 0;
      color: var(--black-color);

      .p-dropdown-label.p-placeholder {
        padding: 0 0 0 0.75rem;
      }
      .p-inputtext {
        padding: 0 0.75rem !important;
      }
      // p-dropdown-label p-inputtext p-placeholder

      &:not(.p-disabled):hover,
      &:not(.p-disabled).p-focus {
        border-color: var(--black-color);
      }
    }
  }
}
