.refer-friend__content {
  h1 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .refer-section {
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 2px solid var(--gray-color);
    p {
      font-size: 20px;
      &:first-of-type {
        width: 40%;
      }
      &:last-of-type {
        font-weight: 700;
      }
    }
  }

  .code {
    svg {
      font-size: 26px;
      margin-inline-start: auto;
      margin-inline-end: 15px;
      cursor: pointer;
    }
  }
}
