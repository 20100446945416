.cart__checkout-holder {
  min-height: 19.1rem;

  .cart__checkout-content {
    width: 80%;
    margin: auto;
    h1 {
      margin-bottom: 2rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    .checkout__stepper {
      &-head {
        :global {
          .p-steps {
            .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
              box-shadow: none;
            }
            .p-steps-item.p-highlight .p-steps-number {
              background-color: var(--black-color);
              color: var(--white-color);
            }
          }
        }
      }
      &-step {
        padding: 3rem 0;
      }
    }

    @media screen and (max-width: 991.5px) {
      width: 100%;
    }
  }
}
