.base__footer {
  background-color: #6a6a6a;
  min-height: 10rem;
  height: 11rem;
  color: var(--white-color);

  .footer__content {
    height: 100%;
    padding: 1.5rem 0 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-weight: 700;
      margin-bottom: 10px;
    }

    &-left {
      width: 45%;
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: flex-start;
      justify-content: space-between;
      .links-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        .footer__links {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          .linksBlock {
            width: 33%;
          }

          p {
            cursor: pointer;
            a {
              text-decoration: none;
              color: var(--white-color) !important;
            }
          }
        }
      }
    }

    &-right {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .social-icons {
        display: flex;
        flex-direction: row;
        a {
          text-decoration: none;
        }
        svg {
          cursor: pointer;
          width: 30px;
          height: 30px;
          margin-inline-end: 20px;
          color: var(--white-color);
        }
      }

      .bottomSide-copyRights {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .copyrights {
          p {
            font-size: 12px;
            margin: 0;
          }
        }

        .powered-by {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin: 0;
            font-size: 12px;
          }
          a {
            text-decoration: none;
            color: var(--white-color);
          }
          img {
            width: 50px;
          }
        }
      }
    }

    // footer inner content media queries

    @media screen and (max-width: 1199.5px) {
      &-right {
        width: 47%;
        .bottomSide-copyRights {
          .copyrights {
            p {
              font-size: 10px;
            }
          }
          .powered-by {
            p {
              font-size: 10px;
            }
          }
        }
      }
      &-left {
        width: 47%;
      }
    }

    @media screen and (max-width: 991.5px) {
      h2 {
        font-size: 20px;
      }
      &-left {
        .linksBlock {
          width: 50% !important;
        }
      }

      &-right {
        .bottomSide-copyRights {
          .powered-by {
            flex-direction: column;

            p {
              font-size: 8px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767.5px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      &-left {
        width: 100%;
        margin-bottom: 2rem;

        .social-icons {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      &-right {
        width: 100%;
      }
    }

    @media screen and (max-width: 575.5px) {
      padding: 1.5rem 2rem 0.5rem;
    }

    @media screen and (max-width: 488.5px) {
      &-right {
        .bottomSide-copyRights {
          margin-top: 1rem !important;
          .copyrights {
            width: 70%;
            p {
              font-size: 8px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767.5px) {
    height: fit-content;
  }
}
