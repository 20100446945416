.address-form__content {
  h1 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .inputCol {
    margin-bottom: 1rem;

    .btn-holder {
      text-align: end;
      margin-top: 1rem;

      button {
        text-decoration: none;
      }
    }
  }
}
