.filterMenu__header {
  margin: 0 0 0.5 !important;
}

// filter section accordion view
:global {
  .p-accordion {
    .p-accordion-tab {
      border-bottom: 2px solid var(--gray-color);
      .p-accordion-header {
        .p-accordion-header-link {
          background-color: transparent !important;
          border: 0;
          color: var(--black-color) !important;
          font-weight: 400;
          border-radius: 0;
          justify-content: space-between;
          flex-direction: row-reverse;
          padding: 0.8rem 0;

          .p-accordion-toggle-icon {
            margin: 0;
          }
        }

        &:hover,
        &:not(.p-disabled),
        &:not(.p-disabled):hover,
        &:not(.p-highlight) {
          .p-accordion-header-link {
            background-color: transparent !important;
            box-shadow: none !important;
          }
        }
      }
      .p-accordion-content {
        overflow-y: scroll;
        border: 0;
        padding: 1rem;
        height: 200px;

        .checkbox-holder {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
          .p-checkbox {
            box-shadow: none;
            .p-checkbox-box.p-highlight {
              background-color: var(--black-color);
              border-color: var(--black-color);
            }
            &:not(.p-checkbox-disabled) .p-checkbox-box:hover {
              border-color: var(--black-color);
            }
            &:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
              border-color: var(--black-color);
              box-shadow: none;
            }
          }
          label {
            margin-inline-start: 10px;
            cursor: pointer;
            font-size: 14px;
          }
        }

        .p-slider-horizontal {
          .p-slider-range {
            background-color: var(--black-color);
          }
          .p-slider-handle {
            border-color: var(--black-color);
            box-shadow: none;

            &:hover {
              background-color: var(--black-color);
            }
          }
        }
      }
    }
    .priceFilter {
      .p-accordion-content {
        height: fit-content;
        overflow: initial;

        p {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.apply-filter {
  width: 100%;
  margin-top: 2rem !important;
}
