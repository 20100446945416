.orderItem-content {
  height: 140px;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
  .item__img {
    border: 2px solid #c5c2c2;
    border-radius: 40px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin-inline-end: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .right-side {
    display: flex;
    flex: 3;
    flex-direction: column;

    .product-name {
      font-weight: 500;
    }
    .data {
      margin-top: 0.3rem;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    p {
      margin: 0;
    }
    .leftSideData {
      text-align: right;
    }
  }

  @media screen and (max-width: 575.5px) {
    .item__img {
      width: 100px;
      height: 100px;
    }
    .product-name {
      font-size: 14px;
    }
    .data {
      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 521.5px) {
    .product-name {
      font-size: 13px;
    }
    .data {
      flex-direction: column !important;
      .rightSideData,
      .leftSideData {
        width: 100%;
        p{
          text-align: start;
        }
      }
    }
  }

  @media screen and (max-width: 387.5px) {
    .product-name{
      font-size: 12px;
    }
  }
}
