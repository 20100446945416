.settingsList-container {
  .account__options {
    h4 {
      margin-bottom: 1rem;
      &:last-of-type {
        margin-top: 2rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: var(--black-color);
      }

      p {
        flex: 1;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--gray-color);
        padding-bottom: 10px;
        cursor: pointer;
      }
    }
  }

  .settings__menu-button {
    position: fixed;
    bottom: 3rem;
    right: 1rem;
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 50% !important;
  }

  .base__settings-menu {
    position: fixed;
    height: 80vh;
    overflow-y: scroll;
    background-color: var(--white-color);
    padding: 1rem 2rem;
    top: 7.8rem;
    left: 0;
    box-shadow: 0 0 10px var(--gray-color);
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 991.5px) {
    .account__options {
      display: none;
    }
  }
  @media screen and (min-width: 991.5px) {
    .base__settings-menu {
      display: none;
    }
    .settings__menu-button {
      display: none;
    }
  }
}
