.base__search {
  width: 100%;
  .p-inputtext {
    border-color: var(--black-color);
    border-radius: 0;
    color: var(--gray-color);
    text-align: center;
    padding: 0.4rem 0.75rem;
    &:focus,
    &:enabled {
      outline: none !important;
      box-shadow: none !important;
      border-color: var(--black-color) !important;
    }
  }
}
