.settingsList {
  width: 70%;
  margin: auto;
  .account__header {
    h2 {
      margin: 0 0 2rem;
      font-weight: 700;
    }
    // .loyalty-meter {
    //   h4 {
    //     margin-bottom: 1.2rem;
    //   }
    //   :global {
    //     .p-slider-horizontal {
    //       .p-slider-range {
    //         background-color: var(--black-color);
    //       }
    //       .p-slider-handle {
    //         border-color: var(--black-color);
    //         box-shadow: none;

    //         &:hover {
    //           background-color: var(--white-color);
    //         }
    //       }
    //     }
    //   }
    //   .loyalty-points {
    //     margin-top: 0.5rem;
    //     margin-bottom: 0;
    //     display: flex;
    //     justify-content: space-between;
    //   }
    // }
  }
  .account__options {
    margin-top: 1.5rem;

    h4 {
      margin-bottom: 1rem;
      &:last-of-type {
        margin-top: 2rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: var(--black-color);
      }

      p {
        flex: 1;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--gray-color);
        padding-bottom: 10px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 991.5px) {
    width: 100%;
  }
}
