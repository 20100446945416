.RefineMenu__header {
  margin: 1rem 0 !important;
}

// Refine section sub-menu
.Refine__nav {
  padding: 1rem 0;
  .Refine__subMenu {
    ul {
      justify-content: space-between;
    }
  }
}

.radio-options {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .radio-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--gray-color);

    :global {
      .p-radiobutton .p-radiobutton-box {
        height: 20px;
        width: 20px;
        &.p-highlight {
          border-color: var(--black-color);
          background-color: var(--black-color);
        }

        &.p-highlight:not(.p-disabled):hover {
          border-color: var(--black-color);
          background-color: var(--black-color);
        }

        &:not(.p-disabled).p-focus {
          box-shadow: none;
        }
        &:not(.p-disabled):not(.p-highlight):hover {
          border-color: var(--black-color);
        }
      }
    }
  }
}
