.base__header {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  border-bottom: 1px solid #c7c7c7;

  &-content {
    padding: 1rem 0;
    background-color: var(--white-color);
    .header__content-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.4rem;

      .top-leftSide {
        flex: 1;

        @media screen and (max-width: 991.5px) {
          display: none;
        }
      }

      .brand {
        flex: 1;
        margin: 0;
        text-align: center;

        a {
          text-decoration: none;
          color: initial;
        }

        @media screen and (max-width: 991.5px) {
          text-align: start;
        }
      }
      .header__actions {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        &-icons {
          display: flex;
          width: 35%;
          justify-content: space-between;
          align-items: center;
          svg {
            color: #707070;
            font-size: 1.5rem;
            cursor: pointer;

            &:hover {
              color: var(--black-color);
            }
          }

          a {
            text-decoration: none;
            color: initial;

            position: relative;

            small {
              position: absolute;
              top: -13px;
              left: 3px;
              color: var(--white-color);
              background-color: var(--gray-color);
              width: 18px;
              height: 18px;
              font-size: 11px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          // menu icon showing on specific width
          .openMenu {
            @media screen and (min-width: 991.5px) {
              display: none;
            }
          }

          // header icons media queries
          @media screen and (max-width: 1199.5px) {
            width: 50%;
          }

          @media screen and (max-width: 767.5px) {
            width: 70%;
          }

          @media screen and (max-width: 575.5px) {
            width: 90%;
          }
        }
        &-buttons {
          display: flex;
          align-items: center;
          button {
            &:first-of-type {
              margin-inline-end: 10px;
            }
          }

          // menu icon showing on specific width
          .openMenu {
            color: #707070;
            font-size: 2rem;
            cursor: pointer;
            margin-inline-start: 10px;

            &:hover {
              color: var(--black-color);
            }

            @media screen and (min-width: 991.5px) {
              display: none;
            }
          }
        }
      }
    }
    .header__content-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .SearchField {
        width: 22%;

        @media screen and (max-width: 1199.5px) {
          width: 25%;
        }

        @media screen and (max-width: 991.5px) {
          display: none;
        }
      }
    }

    .navbar-view {
      flex: 1;
      @media screen and (max-width: 991.5px) {
        display: none;
      }
    }

    @media screen and (max-width: 991.5px) {
      padding: 1rem 0;

      .header__actions {
        &-buttons {
          button {
            padding: 0.5rem 0.6rem !important;
          }
        }
      }
    }
    @media screen and (max-width: 444.5px) {
      .header__actions {
        &-buttons {
          button {
            font-size: 14px;
            &:first-of-type {
              margin-inline-end: 5px !important;
            }
          }
        }
      }
    }
    @media screen and (max-width: 405.5px) {
      .header__actions {
        &-buttons {
          button {
            padding: 0.4rem 0.5rem !important;
            font-size: 12px !important;
          }
          .openMenu {
            font-size: 1.5rem !important;
          }
        }
      }
    }
    @media screen and (max-width: 348.5px) {
      .header__actions {
        &-buttons {
          button {
            font-size: 11px !important;
          }
          .openMenu {
            font-size: 1.2rem !important;
          }
        }
      }
    }
  }

  &-sale {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: var(--red-color);
    color: var(--white-color);
    padding: 0.5rem 0;
    p {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      span {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }

  &-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 5rem 2rem 0;
    width: 45%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;
    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

    svg {
      position: absolute;
      top: 1rem;
      right: 2rem;
      width: 35px;
      height: 35px;
      color: var(--gray-color);
    }

    .menu__searchField {
      margin: 2rem 0;
    }
    .menu__subMenu {
      ul {
        justify-content: space-between;
        li {
          margin: 0 0.5rem;
          font-size: 16px;

          @media screen and (max-width: 575.5px) {
            margin: 0 0.2rem;
          }
        }
      }
    }

    .menu_categories {
      margin-top: 2rem;
      ul {
        flex-direction: column;
        align-items: flex-start;
        li {
          width: 100%;
          margin-bottom: 10px;
          padding-bottom: 5px;
          font-size: 16px;
          border-bottom: 2px solid var(--gray-color);
        }
      }
    }

    @media screen and (max-width: 767.5px) {
      width: 50%;
    }

    @media screen and (max-width: 575.5px) {
      width: 70%;
    }

    @media screen and (max-width: 460px) {
      width: 90%;
    }
  }
}
