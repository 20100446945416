.base__home-categoryView {
  margin-bottom: 1.5rem;
  h4 {
    font-weight: bold;
  }
  p {
    font-weight: 500;
    margin-bottom: 20px;
  }
}
