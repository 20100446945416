.base__products {
  margin-top: 7rem;
  padding: 3rem 0;

  // product page content section

  &-content {
    justify-content: space-between;

    // product page filter section - left side
    .products-filter {
      margin-top: 1rem;
    }

    // product page grid view - right side
    .products-grid {
      h2 {
        font-weight: 700;
        margin-bottom: 2rem;
      }
      .category-quote {
        margin-bottom: 2rem;
      }
      .products-list {
        div {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .filter__menu-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 4rem !important;
    height: 4rem !important;
    border-radius: 50% !important;

    span {
      font-size: 25px;
    }
  }

  .base__filter-menu {
    position: fixed;
    height: 90vh;
    overflow-y: scroll;
    background-color: var(--white-color);
    padding: 1rem 2rem;
    top: 4.8rem;
    left: 0;
    box-shadow: 0 0 10px var(--gray-color);
    transition: all 0.3s ease-in-out;
    z-index: 4;
  }

  @media screen and (max-width: 1199.5px) {
    .products-filter {
      margin-top: 1.5rem;
    }
  }

  @media screen and (max-width: 991.5px) {
    padding-top: 0;

    .products-filter {
      display: none;
    }
  }

  @media screen and (min-width: 991.5px) {
    .filter__menu-button {
      display: none;
    }
    .base__filter-menu {
      display: none;
    }
  }

  @media screen and (max-width: 767.5px) {
    // margin-top: 7rem;

    .products-filter {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 575.5px) {
    padding: 0 2rem;
  }
}

ul.paginationContainer {
  list-style: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  li {
    width: 40px;
    height: 40px;
    margin: 0 1rem;
    border: 2px solid var(--black-color);

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:first-child,
    &:last-child {
      width: 100px;
      text-align: center;
    }

    &:hover {
      background-color: var(--black-color);
      a {
        color: #fff !important;
      }
    }
  }
  li.activePage {
    background-color: #000;
    a {
      color: #fff !important;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-bottom: 3rem;

  img {
    width: 150px;
  }
}

@media screen and (max-width: 460px) {
  ul.paginationContainer {
    li {
      width: 40px;
      height: 40px;
      margin: 0 1rem;

      a {
        width: 100%;
        height: 100%;
      }

      &:first-child,
      &:last-child {
        width: 60px;
        font-size: 12px;
      }
    }
  }
}
