.ordersAndReturns__content {
  h1 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .order-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      margin: 0;
      margin-inline-end: 2rem;
      // cursor: pointer;
      padding-bottom: 0.5rem;

      &.active {
        border-bottom: 4px solid;
      }
    }
  }
}
