.payment-container {
  .payment-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      margin: 0;
      margin-inline-end: 2rem;
      // cursor: pointer;
      padding-bottom: 0.5rem;

      &.active {
        border-bottom: 4px solid;
      }
    }
  }
  .cardInfo__form {
    margin-bottom: 1rem;
    .inputCol {
      margin-bottom: 1rem;
    }
  }
  .total__section {
    p {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 20px;
      padding-bottom: 1rem;

      span {
        &:last-of-type {
          font-weight: 700;
        }
      }
    }
  }

  .onDelivery__option-Btns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 2rem;
    button {
      width: 50%;
      &:first-of-type {
        margin-bottom: 1rem;
      }
      @media screen and (max-width: 991.5px) {
        width: 70% !important;
      }
    }
  }
  .payNow__option-Btns {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    button {
      width: 50%;

      @media screen and (max-width: 991.5px) {
        width: 70% !important;
      }
    }
  }
}
