.address-container {
  h2 {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .address-list {
    a {
      text-decoration: none;
      color: var(--black-color);

      .newAddressLink {
        margin: 0;
        padding: 2rem 0;
        border-bottom: 2px solid var(--gray-color);
        cursor: pointer;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .btn-holder {
    text-align: center;
    margin-top: 2rem;

    button {
      margin-top: 2rem;
      text-decoration: none;
      width: 50% !important;

      @media screen and (max-width: 991.5px) {
        width: 70% !important;
      }
    }
  }
}
