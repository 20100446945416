.inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  label {
    font-size: 14px;
    color: var(--gray-color);

    span{
      color: var(--red-color);
    }
  }
  :global {
    .p-inputtext {
      width: 100%;
      border-radius: 0;
      border: 0;
      border-bottom: 2px solid var(--gray-color);
      box-shadow: none !important;
      padding: 0.5rem;
      color: var(--black-color);

      &:hover,
      &:focus {
        border-bottom: 2px solid var(--black-color) !important;
      }
    }
  }

  small {
    color: var(--red-color);
  }
}
