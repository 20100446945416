.base__brands {
  margin-top: 11.2rem;
  padding: 4rem 0;
  min-height: 70vh;
  &-content {
    justify-content: center;

    .brands-search {
      padding: 1rem;

      @media screen and (max-width: 1199.5px) {
        padding: 1rem 0;
      }
    }
    .brands-section {
      .favorite-brands {
        text-align: center;
        margin-bottom: 3rem;
        .emptyList-icon {
          width: 50px;
          height: 50px;
          margin-bottom: 1rem;
        }
        p {
          font-size: 20px;
        }
        .favBrands__list {
          display: flex;
          flex-direction: column;

          &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1rem 0;
            border-bottom: 2px solid var(--gray-color);
            p {
              margin: 0;
              cursor: pointer;
            }
            svg {
              font-size: 20px;
              margin-inline-end: 20px;
              cursor: pointer;
              fill: #d63c3c;
            }
          }
        }
      }
      h2 {
        text-align: center;
        margin-bottom: 1rem;
      }
      .brands__list {
        display: flex;
        flex-direction: column;

        &-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 0;
          border-bottom: 2px solid var(--gray-color);
          p {
            margin: 0;
            cursor: pointer;
          }
          svg {
            font-size: 20px;
            cursor: pointer;

            &.addedToFav {
              fill: var(--red-color);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 991.5px) {
    padding-top: 0;
    margin-top: 8.5rem;

    .brands-search {
      margin: 2rem 0;
    }
  }

  @media screen and (max-width: 575.5px) {
    padding: 0 2rem 4rem;
  }
}

ul.paginationContainer {
  margin-top: 2rem;
  list-style: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  li {
    width: 40px;
    height: 40px;
    margin: 0 1rem;
    border: 2px solid var(--black-color);

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:first-child,
    &:last-child {
      width: 100px;
      text-align: center;
    }

    &:hover {
      background-color: var(--black-color);
      a {
        color: #fff !important;
      }
    }
  }
  li.activePage {
    background-color: #000;
    a {
      color: #fff !important;
    }
  }
}