.cart__list-holder {
  min-height: 19.1rem;

  .cart__list-content {
    width: 70%;
    margin: auto;
    h1 {
      margin-bottom: 2rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    .cart__list {
      padding: 2rem 0;

      .emptyCart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 5rem;

        svg {
          font-size: 4rem;
          margin-bottom: 2rem;
        }
        h4 {
          border: 2px solid;
          a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 1rem;
            &:hover {
              background-color: #000;
              color: #fff !important;
            }
          }
        }
      }
    }
    .card__total {
      margin-bottom: 3rem;
      h3 {
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: start;
      }
      p {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;

        span {
          &:last-of-type {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }

      .btn-holder {
        text-align: center;
        a {
          text-decoration: none;
        }
        button {
          margin-top: 2rem;
          text-decoration: none;
        }
      }
    }

    @media screen and (max-width: 1199.5px) {
      width: 100%;
      margin: 0;
    }
    @media screen and (max-width: 575.5px) {
      padding: 0 2rem;

      h1 {
        margin-bottom: 0;
      }
    }
  }
}
