.address__listItem {
  border-bottom: 2px solid var(--gray-color);
  padding: 1rem 0;

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      color: var(--gray-color);
      &.chooseAddress {
        color: var(--black-color);
      }
    }

    @media screen and (max-width: 575.5px) {
      p {
        font-size: 14px;
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;

    p {
      color: var(--black-color);
      margin-bottom: 2rem;
      cursor: pointer;
    }
    svg {
      cursor: pointer;
      font-size: 20px;
    }
  }
}
