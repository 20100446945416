.user-details__content {
  h1 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .inputCol {
    margin-bottom: 1rem;
  }
  .btn-holder {
    margin-top: 2rem;
    text-align: end;

    a {
      text-decoration: none;
    }
  }
  .delete-account {
    margin-top: 4rem;

    p {
      width: 70%;
    }
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      p {
        margin: 0;
        border: 2px solid var(--gray-color);
        width: 180px;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;

        &:first-of-type {
          margin-inline-end: 20px;
        }
      }
    }
  }
}

:global {
  .p-dialog {
    .p-dialog-content {
      h2{
        margin: 2rem 0;
      }
      button {
        margin-inline-end: 10px;
      }
    }
  }
}
