.wishlist-content {
  min-height: 80vh;

  .emptyList{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h1{
      text-align: center;
    }

    @media screen and (max-width: 420px) {
      h1{
        font-size: 25px;
      }
    }
  }

}
