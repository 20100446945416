.address-book__content {
  h1 {
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .address-list {
    .newAddressLink {
      margin: 0;
      padding: 2rem 0;
      border-bottom: 2px solid var(--gray-color);
      cursor: pointer;
      a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-decoration: none;
        color: var(--black-color);
      }
    }
  }
}
