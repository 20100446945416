:global {
  .p-button {
    background: transparent;
    padding: 0.5rem 1rem !important;
    border-radius: 0 !important;

    &-label {
      font-weight: 400 !important;
    }
    &:focus {
      box-shadow: none !important;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background-color: var(--black-color);
      border-color: var(--black-color);
    }

    &.btn-primary {
      background-color: var(--black-color);
      color: var(--white-color);
      border: 2px solid var(--black-color);

      &:hover {
        background-color: var(--white-color) !important;
        border: 2px solid var(--black-color) !important;
        color: var(--black-color) !important;
      }
    }

    &.btn-secondary {
      background-color: var(--white-color) !important;
      color: var(--black-color) !important;
      border: 2px solid var(--gray-color) !important;

      &:hover {
        border: 2px solid var(--black-color) !important;
      }
    }

    @media screen and (max-width: 411.5px) {
      padding: 0.3rem 0.7rem !important;
    }
  }
}
