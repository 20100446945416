.contact-container {
  h1 {
    margin-bottom: 3rem;
    font-weight: 700;
  }

  h3 {
    a {
      text-decoration: underline !important;
    }
  }

  .socials {
    margin-top: 2rem;
    .socialItem {
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
      font-size: 20px;

      P {
        margin: 0;
      }
      svg {
        margin-inline-end: 15px;
      }
    }
  }

  @media screen and (max-width: 574.5px) {
    .socials {
      .socialItem {
        flex-direction: column;
        align-items: flex-start;
        font-size: 15px;
      }
    }
  }
}
