@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

:root {
  --font-base: "Montserrat", sans-serif;

  --white-color: #fff;
  --black-color: #000;
  --gray-color: #707070;
  --red-color: #d63c3c;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  scroll-behavior: smooth;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: var(--font-base);
}
