.base__message-container {
  margin: 11rem 0 6rem;
  padding-top: 6rem;
  min-height: 22.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 0.5rem;
    &.message-head {
      color: var(--black-color);
      font-size: 20px;
    }
    &.message-content {
      color: var(--gray-color);
      font-size: 14px;
    }
    &.message-box {
      font-size: 24px;
      border: 2px solid;
      width: 25%;
      padding: 1rem 2rem;
      margin: 3rem 0;
      text-align: center;
      span {
        font-weight: 700;
      }
    }
  }

  a {
    text-decoration: none;
    color: initial;

    p{
      font-size: 20px;
    }

    &:hover{
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 991.5px) {
    p.message-box {
      width: 40%;
    }
  }

  @media screen and (max-width: 575.5px) {
    p.message-box {
      width: 60%;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 424.5px) {
    p.message-box {
      width: 80%;
    }
  }
}
