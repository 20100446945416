.about-container {

 h1{
    font-weight: 700;
    &:not(:first-of-type){
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
 }
}
