.base__App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.full-screen-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  max-width: 90vw;
  z-index: 10000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; /* Optional: Enables smooth scrolling on iOS */
}

.image {
  flex: 0 0 auto;
  margin-right: 10px; /* Optional: Adds some horizontal spacing between images */
  width: 90%;
}
@media only screen and (max-width: 767px) {
  .p-galleria {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  .p-galleria .p-galleria-item {
    display: inline-block !important;
    margin-right: 1rem;
    vertical-align: top;
    white-space: normal;
    max-width: none !important;
  }
}
