.base__product-card {
  margin: 0 0 10px 0;
  position: relative;
  cursor: pointer;

  a {
    text-decoration: none;
    color: initial;
  }
  .card__img {
    height: 70%;
    margin-bottom: 11px;

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
  .card__info {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0 !important;
    .product-name{
      font-weight: 500;
    }
    .info-downSide {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    p {
      margin: 0;
      font-weight: 400;
      font-size: 13px;
      &.product-brand {
        font-size: 12px;
        color: #929292;
      }
    }
    .product-price {
      display: flex;
      .price {
        color: var(--gray-color);
        margin-inline-end: 10px;
        font-size: 16px;
        .original{
          text-decoration: line-through;
          margin-inline-end: 15px;
        }
        .sale{
          font-size: 14px;
          color: var(--red-color);
        }
      }
      .discount-price {
        color: var(--red-color);
      }
    }
  }

  .favBtn {
    position: absolute;
    right: 0;
    bottom: 15px;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    background-color: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 5px);

    svg {
      &.addedToFav {
        fill: var(--red-color);
      }
    }
  }
}
