.base__productView {
  margin-top: 9rem;
  min-height: 70vh;
  &-content {
    justify-content: space-between;

    .product__gallery {
      :global {
        .p-galleria {
          .p-galleria-content {
            height: 68vh;
            overflow: hidden;

            ::-webkit-scrollbar {
              display: none;
            }

            .p-galleria-item-wrapper {
              flex: 3;
              justify-content: center;
              padding: 0 1rem;

              .p-galleria-item-container {
                img {
                  padding: 0;
                  width: 330px !important;
                  aspect-ratio: 5/7;
                  object-fit: contain;
                }
              }

              .p-galleria-indicators {
                width: 10%;
                .p-galleria-indicator.p-highlight {
                  button {
                    background-color: #000;
                    box-shadow: none;
                  }
                }
              }
            }

            .p-galleria-thumbnail-wrapper {
              flex: 1;
              height: 100%;

              .p-galleria-thumbnail-container {
                justify-content: space-between;
                background-color: #fff;
                align-items: center;
                padding: 0;
                height: 100%;

                .p-galleria-thumbnail-items-container {
                  height: 405px !important;

                  .p-galleria-thumbnail-item {
                    margin-bottom: 10px;
                  }

                  .p-galleria-thumbnail-item-content {
                    height: 100%;

                    &:focus {
                      box-shadow: none;
                    }

                    img {
                      width: 90px;
                      aspect-ratio: 5/7;
                      padding: 5px;
                      display: block;
                      margin: auto;
                      object-fit: cover;

                      @media screen and (max-width: 420.5px) {
                        width: 80px;
                        height: 112px;
                      }
                    }
                  }
                }
              }

              .p-galleria-thumbnail-prev,
              .p-galleria-thumbnail-next {
                color: #000;
                &:focus {
                  box-shadow: none;
                }
              }
            }

            @media screen and (max-width: 1199.5px) {
              margin: 0.5rem 0;
              height: 64vh;
            }

            @media screen and (max-width: 991.5px) {
              height: 70vh;
              .p-galleria-item-container {
                img {
                  width: 80% !important;
                }
              }
            }

            @media screen and (max-width: 767.5px) {
              .p-galleria-item-wrapper {
                padding: 0 !important;
              }
              .p-galleria-item-container {
                width: 90%;
              }
              .p-galleria-indicators {
                padding: 0;
              }
            }

            @media screen and (max-width: 574.5px) {
              height: calc(100vh - 170px);
              margin: 0.5rem 0 0;
              justify-content: space-between;
              .p-galleria-item-wrapper {
                justify-content: center;
                flex-direction: row;
                flex: none;
                height: 100%;
                .p-galleria-item-container {
                  img {
                    width: 320px !important;
                  }
                }
              }

              .p-galleria-thumbnail-wrapper {
                flex: none;
                .p-galleria-thumbnail-container {
                  .p-galleria-thumbnail-items-container {
                    height: fit-content !important;
                    .p-galleria-thumbnail-item {
                      margin-bottom: 0;
                    }
                  }

                  .p-galleria-thumbnail-prev,
                  .p-galleria-thumbnail-next {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }
            }

            @media screen and (max-width: 470px) {
              height: 60vh;
              .p-galleria-item-wrapper {
                height: fit-content;
                .p-galleria-item-container {
                  img {
                    width: 280px !important;
                  }
                }
              }
            }
            @media screen and (max-width: 420px) {
              .p-galleria-item-wrapper {
                padding: 0 0 0 10px;
                .p-galleria-item-container {
                  img {
                    // width: 220px !important;
                    // height: 308px;
                  }
                }
              }
            }
            @media screen and (max-width: 374.5px) {
              .p-galleria-item-wrapper {
                .p-galleria-item-container {
                  img {
                    width: 250px !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .product__generalInfo {
      padding: 1rem 0 0 2rem;

      .product__id {
        &-name {
          margin-bottom: 0.5rem;
        }
        &-brand {
          color: #929292;
          font-size: 14px;
          margin: 0 0 1rem 0;
        }
        &-price {
          font-size: 25px;
          color: var(--gray-color);
          margin: 0;
          span {
            &.originalPrice {
              margin-inline-end: 20px;
              text-decoration: line-through;
            }
            &.salePrice {
              font-size: 20px;
              color: var(--red-color);
            }
          }
        }
      }
      .product__sizes {
        margin-top: 1.5rem;
        width: 50%;
      }
      .product__colors {
        width: 50%;
        margin: 1.5rem 0 0;
      }
      .item-counter {
        margin: 2rem 0 1rem;
        width: 150px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: #0000000f;
        border-radius: 20px;
        padding: 7px 10px;
        p {
          margin: 0;
        }
        i {
          cursor: pointer;
          padding: 3px;
          &:hover {
            background-color: var(--white-color);
            border-radius: 50%;
          }
        }
      }
      .stockHint {
        color: var(--red-color);
      }
      .product__action-icons {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        svg {
          font-size: 30px;
          color: var(--gray-color);
          cursor: pointer;
          &.favIcon {
            margin-inline-end: 15px;

            &.active {
              fill: var(--red-color);
              color: var(--black-color);
            }
          }

          &:hover {
            color: var(--black-color);
          }
        }
      }

      button {
        &:first-of-type {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }

    .product__details {
      margin-top: 2rem;
      justify-content: space-between;
      div {
        margin-top: 1rem;
      }
      h4 {
        font-weight: 700;
        margin-bottom: 1rem;
      }
      p {
        font-size: 14px;
        text-align: justify;
        width: 90%;
      }
    }

    .slider__container {
      margin: 3rem 0;
      .custom__img-width {
        margin-inline-end: 50px;
        img {
          width: 275px;
        }
      }
    }
  }

  :global {
    .p-toast {
      .p-toast-message {
        box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.1);
      }
      .p-toast-message.p-toast-message-info {
        color: var(--black-color);
        border-color: var(--black-color);
        background: var(--white-color);
      }
      .p-toast-message.p-toast-message-info .p-toast-message-icon,
      .p-toast-message.p-toast-message-info .p-toast-icon-close {
        color: var(--black-color);
      }
    }
  }

  @media screen and (max-width: 991.5px) {
    padding-top: 0;
    margin-top: 6rem;

    .product__generalInfo {
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 574.5px) {
    .product__generalInfo {
      padding: 0 1rem !important;

      .product__sizes,
      .product__colors {
        width: 50%;
      }
    }
    .product__details {
      padding: 0 1rem;
      p {
        width: 100%;
      }
    }
    .slider__container {
      .custom__img-width {
        margin-inline-end: 40px;
        img {
          width: 160px;
          height: 224px;
        }
      }
    }
  }

  @media screen and (max-width: 390.5px) {
    &-content {
      .product__generalInfo {
        .product__id {
          &-name {
            font-size: 20px;
          }
        }
        .product__sizes {
          width: 70% !important;
        }
        .product__colors {
          width: 70%;
        }
      }
    }
  }

  @media screen and (max-width: 370px) {
    .product__generalInfo {
      margin-top: 0;
    }
  }
}
