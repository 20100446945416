.address__listItem {
  border-bottom: 2px solid var(--gray-color);
  padding: 1rem 1rem 1rem 0;
  cursor: pointer;

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      color: var(--gray-color);
    }

    .actions {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      & > svg {
        width: 25px;
        height: 25px;
        margin: 0 10px 2rem 0;
        color: #c3c3c3;

        &.selected {
          color: var(--black-color);
        }
      }

      .editLink {
        padding: 10px;
        &:hover {
          background-color: var(--black-color);

          a {
            color: #fff !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 575.5px) {
    padding-inline-end: 0;
    p {
      font-size: 14px;
    }
    .addressInfo {
      width: 70%;
    }

    .actions {
      align-items: flex-end;
      width: 30%;
      .editLink {
        font-size: 13px;
      }
    }
  }
  @media screen and (max-width: 451px) {
    p {
      font-size: 13px;
    }
    .actions {
      .editLink {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 425.5px) {
    .addressInfo {
      width: 65%;
    }
    .actions {
      width: 35%;
      .editLink {
        padding: 5px;
      }
    }
  }
}
