.base__home-SubImage {
  .subImageContainer {
    height: 600px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }
    margin-bottom: 20px;

    @media screen and (max-width: 575.5px) {
      height: 400px;
    }
  }
  p {
    color: var(--gray-color);
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
}
