a {
  text-decoration: none !important;
  color: var(--black-color) !important;
}

.orders__list-item {
  height: 140px;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--gray-color);
  cursor: pointer;
  .item__img {
    border: 2px solid #c5c2c2;
    border-radius: 40px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    margin-inline-end: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .order-rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .order-details {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 95%;
      p {
        margin: 0;
      }
    }
    i {
      text-align: end;
    }
  }

  @media screen and (max-width: 991.5px) {
    height: fit-content;
    .order-rightSide {
      position: relative;

      .order-details {
        margin: 1rem 0 0;
        flex-direction: row;
        justify-content: flex-start;
        flex: initial;

        div {
          width: 50%;
          margin-bottom: 1rem;
        }
      }

      i {
        position: absolute;
        bottom: 2rem;
        right: 1rem;
      }
    }
  }

  @media screen and (max-width: 767.5px) {
    .order-details {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 511.5px) {
    flex-direction: column !important;
    align-items: flex-start;

    .order-rightSide{
      width: 100%;
      i{
        right: 0;
      }
    }
  }
}
