.passwordField {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  label {
    font-size: 14px;
    color: var(--gray-color);
    span {
      color: var(--red-color);
    }
  }
  :global {
    .p-password {
      .p-inputtext {
        width: 100%;
        border-radius: 0;
        border: 0;
        border-bottom: 2px solid var(--gray-color);
        box-shadow: none !important;
        padding: 0.5rem 0;
        color: #b2b2b2;

        &:hover,
        &:focus {
          border-bottom: 2px solid var(--black-color) !important;
        }
      }
    }
  }
  ul {
    margin: 5px 0 0 0;
    padding: 0 0 0 15px;
    li {
      font-size: 10px;
      padding-left: 15px;
      text-transform: capitalize;
      padding: 0;
      color: var(--red-color);

      &:last-of-type {
        margin-top: 2px;
      }
    }
  }
}
