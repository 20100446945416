.base__navbar {
  &-links {
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    li {
      margin-inline-end: 1.5rem;
      font-size: 16px;
      cursor: pointer;
      border-bottom: 2px solid transparent;

      a {
        text-decoration: none;
        color: var(--black-color);
      }

      &:last-of-type {
        margin-inline-end: 0;
      }

      &.highlighed {
        color: #f00;
      }

      &:hover {
        border-color: var(--black-color);
      }

      @media screen and (max-width: 1199.5px) {
        font-size: 14px;
        margin-inline-end: 1rem;
      }
    }
  }
}
