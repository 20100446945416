.base__slider {
  ::-webkit-scrollbar {
    display: none;
  }
  .slider {
    overflow-x: scroll;
    .product-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start !important;

      a {
        text-decoration: none;
        color: initial;
      }
    }
  }
}
