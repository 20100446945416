.base__home {
  margin-top: 8rem;
  margin-bottom: 0rem;

  .home__hero-section {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: calc(100vh - 230px);
    padding: 0 1rem;
    margin-bottom: 2rem;

    .home__base-message {
      flex: 1;
      padding: 0 2rem;
      h3 {
        font-weight: bold;
        margin-bottom: 2rem;
      }
      p {
        margin: 0;
        font-size: 16px;
      }
      .message-btn {
        margin-top: 2rem;
        text-align: center;
        .btn-customWidth {
          width: 50%;
        }
      }
    }
    .home__hero-img {
      margin-top: 2rem;
      flex: 1;
      height: 100%;
      padding: 0;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 991.5px) {
      height: fit-content;
    }
  }

  .home__body-gallery {
    padding: 3rem 1rem;

    .subImagesView {
      padding-left: 3rem;
      .mainImage {
        margin-bottom: 4rem;
      }
      .subImages {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .SubImage-custom {
          width: 45%;
          div {
            height: 400px;
          }
          p {
            text-align: justify;
          }
        }

        @media screen and (max-width: 500px) {
          flex-direction: column;

          .SubImage-custom {
            width: 100%;
            margin-bottom: 1rem;
            div {
              height: 500px;
            }
          }
        }
      }
    }

    .subCategoriesView {
      .custom__img-width {
        width: 152px;
        margin-inline-end: 42px;

        img {
          width: 152px;
          height: 212px;
        }
      }
    }
  }

  .home__comingSoon-section {
    padding: 0 1rem;
    img {
      width: 100%;
      margin-bottom: 2rem;
    }
    h4 {
      font-weight: 700;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 1199.5px) {
    .home__hero-section {
      .home__base-message {
        h3 {
          font-size: 24px;
        }
        p {
          font-size: 14px;
        }
      }
      .home__hero-img {
        img {
          width: 90%;
        }
      }
    }
  }

  @media screen and (max-width: 991.5px) {
    .home__hero-section {
      padding: 0;

      .home__base-message {
        padding-left: 0;
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 11.7px;
        }
      }

      .home__hero-img {
        img {
          width: 95%;
        }
      }
    }
    .home__body-gallery {
      padding: 4rem 0;
      flex-direction: column-reverse;

      .subImagesView {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .subCategoriesView {
        margin-top: 2rem;
      }
    }
  }

  @media screen and (max-width: 767.5px) {
    margin-top: 7rem;

    .home__hero-section {
      flex-direction: column-reverse;
      height: fit-content;
      .home__hero-img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .home__base-message {
        width: 100%;
        margin-top: 1rem;
        padding-right: 0;
        p {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .home__body-gallery {
      padding-top: 3rem;
      p {
        font-size: 12px;
        font-weight: 400;
      }
      .subImagesView {
        .mainImage {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
