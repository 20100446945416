.change-password__content {
  h1 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .inputCol {
    margin-bottom: 1rem;
  }
  .btn-holder {
    margin-top: 2rem;
    text-align: end;
  }
}
