.summary__address {
  padding: 1rem 0;
  cursor: pointer;

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      color: var(--gray-color);
    }
  }
}

.summary__paymentMethod {
  p {
    margin: 0;
    color: var(--gray-color);
  }
}

.summary__productList {
  margin-top: 2rem;
  .list__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--gray-color);

    p {
      margin: 0;
    }

    &-leftSide {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;

      .leftSide-img {
        border: 2px solid #c5c2c2;
        border-radius: 40px;
        width: 120px;
        height: 120px;
        overflow: hidden;
        margin-inline-end: 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    &-rightSide {
      display: flex;
      width: 85%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .rightSide-info {
        p {
          &:first-of-type {
            font-weight: 600;
          }
        }
      }
      .pq {
        text-align: end;
        .price {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          p {
            &:first-of-type {
              margin-inline-end: 5px;
            }
          }
          span {
            &.original {
              color: var(--gray-color);
              text-decoration: line-through;
              margin-inline-end: 10px;
            }
            &.sale {
              font-size: 14px;
              color: var(--red-color);
            }
          }
        }
      }
    }

    @media screen and (max-width: 767.5px) {
      &-rightSide {
        .rightSide-info {
          font-size: 14px;
        }
      }
    }

    @media screen and (max-width: 530px) {
      &-rightSide {
        flex-direction: column;
        .rightSide-info {
          width: 100%;
          margin-bottom: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
        .pq {
          display: flex;
          width: 100%;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: flex-start;
          font-size: 14px;
          .price {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.summary__total {
  margin-top: 2rem;
  p {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    padding-bottom: 1rem;

    span {
      &:last-of-type {
        font-weight: 700;
      }
    }
  }
}

.summary__checkoutBtn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  button {
    width: 50%;

    @media screen and (max-width: 991.5px) {
      width: 70% !important;
    }
  }
}
