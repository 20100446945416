.orderDetails__content {
  h1 {
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .order-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    div {
      margin-inline-end: 30px;
      p {
        margin: 0;
        color: var(--gray-color);
      }
    }
  }

  .delivery-address {
    margin-top: 3rem;
    h4 {
      font-weight: 700;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .order-summary {
    margin-top: 3rem;
    border-bottom: 2px solid var(--gray-color);
    h4 {
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 20px;
      padding-bottom: 10px;

      span {
        &:last-of-type {
          font-weight: 700;
        }
      }
    }
  }
  .contact {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    h4 {
      font-weight: 700;
      margin: 0 20px 0 0;
    }
    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      p {
        margin: 0;
        border: 2px solid var(--gray-color);
        width: 140px;
        text-align: center;
        cursor: pointer;

        &:first-of-type {
          margin-inline-end: 20px;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px 0;
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    .order-details {
      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 491.5px) {
    .contact {
      flex-direction: column;
      align-items: flex-start;

      h4 {
        margin-bottom: 1rem;
      }
    }

    .order-details {
      flex-wrap: wrap;
      div {
        width: 40%;
        margin-bottom: 1rem;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
